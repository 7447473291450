var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"our-services section-full bg-primary p-tb90"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-head text-left text-white"},[_c('h2',{staticClass:"text-uppercase font-36"},[_vm._v("\n        "+_vm._s(_vm.$t('nos services'))+"\n      ")]),_vm._v(" "),_c('h4',{},[_vm._v("\n        "+_vm._s(_vm.$t('Notre structure fonctionne en pôle immobilier couvrant tous vos besoins'))+"...\n      ")]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"section-content circle-block-outer",attrs:{"data-toggle":"tab-hover"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-12 m-b30"},_vm._l((_vm.ourServices.slice(0, 2)),function(value,index){return _c('div',{key:index,staticClass:"wt-icon-box-wraper right p-a20 m-b50 text-white",attrs:{"data-target":'#'+value.id,"data-toggle":"tab"}},[_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({
                name: 'news-category-slug'
                , params: {
                  category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.category.slug, 'tag')
                  , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.slug, 'article')
                }
              })}},[_c('div',{staticClass:"icon-content"},[_c('h4',{staticClass:"wt-tilte text-uppercase"},[_vm._v("\n                  "+_vm._s(_vm.$t(value.headline))+"\n                ")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t(value.articleResume)))])])])],1)}),0),_vm._v(" "),_c('div',{staticClass:"col-md-4 col-sm-12 m-b30"},[_c('div',{staticClass:"circle-content-pic tab-content"},_vm._l((_vm.ourServices),function(value,index){return _c('div',{key:index,staticClass:"tab-pane",class:[index === 0 ? 'active' : ''],attrs:{"id":value.id}},[_c('div',{staticClass:"wt-box"},[_c('div',{staticClass:"wt-media text-primary m-b20 lz-loading ratio-container unknown-ratio-container"},[_c('img',{staticClass:"lazyload tablet",class:[ _vm.checkTablet ? '': 'hidden' ],attrs:{"width":_vm.$getImageSizeByFilterSets('width', _vm.getFormat('tablet')),"height":_vm.$getImageSizeByFilterSets('height', _vm.getFormat('tablet')),"src":_vm.getImagePath(value.primaryImage, 'tablet'),"alt":value.primaryImage.alt}}),_vm._v(" "),_c('img',{staticClass:"lazyload desktop",class:[ !_vm.checkTablet ? 'hidden': '' ],attrs:{"width":_vm.$getImageSizeByFilterSets('width', _vm.getFormat('desktop')),"height":_vm.$getImageSizeByFilterSets('height', _vm.getFormat('desktop')),"src":_vm.getImagePath(value.secondaryImage, 'desktop'),"alt":value.secondaryImage.alt}})])])])}),0)]),_vm._v(" "),_c('div',{staticClass:"col-md-4 col-sm-12 m-b30"},_vm._l((_vm.ourServices.slice(2,4)),function(value,index){return _c('div',{key:index,staticClass:"wt-icon-box-wraper left p-a20 m-b50 text-white",attrs:{"data-target":'#'+value.id,"data-toggle":"tab"}},[_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({
                name: 'news-category-slug'
                , params: {
                  category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.category.slug, 'tag')
                  , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.slug, 'article')
                }
              })}},[_c('div',{staticClass:"icon-content"},[_c('h4',{staticClass:"wt-tilte text-uppercase"},[_vm._v("\n                  "+_vm._s(_vm.$t(value.headline))+"\n                ")]),_vm._v(" "),_c('p',[_vm._v(" "+_vm._s(_vm.$t(value.articleResume))+" ")])])])],1)}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wt-separator-outer"},[_c('div',{staticClass:"wt-separator bg-white"})])
}]

export { render, staticRenderFns }